// @ts-strict-ignore
import { Debug } from 'bb/app/debug';
import { type GiftcardStore } from 'bb/giftcard/types';
import { type Product } from 'bb/subscription/types';

const storeName = 'giftcardStore';

const debug = Debug(storeName);

const initialState = {
    selectedGiftcardProduct: {
        value: null,
        confirmed: false
    },
    selectedGiftcardType: {
        value: null,
        confirmed: false
    },
    numberOfGiftcards: 0,
    guestEmail: '',
    paymentData: null,
    totalAmount: 0,
    giftcardCodes: []
};

export function giftcardStore(): GiftcardStore {
    return {
        ...initialState,
        setSelectedGiftcardProduct(selectedGiftcardProduct: Product) {
            debug.info('setSelectedGiftcardProduct');
            this.selectedGiftcardProduct.value = selectedGiftcardProduct;
            this.selectedGiftcardProduct.confirmed = true;
        },
        incrementNrOfGiftcards() {
            this.numberOfGiftcards += 1;
        },
        decrementNrOfGiftcards() {
            this.numberOfGiftcards -= 1;
        },
        setNumberOfGiftcards(value) {
            this.numberOfGiftcards = value;
        },
        setTotalAmount(value) {
            this.totalAmount = value;
        },
        setGuestEmail(value) {
            this.guestEmail = value;
        },
        setSelectedGiftcardType(type) {
            this.selectedGiftcardType.value = type;
            this.selectedGiftcardType.confirmed = true;
        },
        invalidateSelectedGiftcardProduct() {
            this.selectedGiftcardProduct.confirmed = false;
        },
        invalidateSelectedGiftcardType() {
            this.selectedGiftcardType.confirmed = false;
        },
        setPaymentData(values) {
            this.paymentData = values;
        },
        setGiftcardCodes(codes) {
            this.giftcardCodes = codes;
        },
        clearStore() {
            const values = { value: null, confirmed: false };
            this.selectedGiftcardProduct = { ...values };
            this.selectedGiftcardType = { ...values };
            this.guestEmail = '';
            this.numberOfGiftcards = 0;
            this.paymentData = null;
            this.giftcardCodes = [];
        }
    };
}
