// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { giftcardStore } from './giftcardStore';
import { type GiftcardStore } from './types';

export const StoreContext = React.createContext<GiftcardStore | null>(null);

export const GiftcardStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(giftcardStore);
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export const useGiftcardStore = (): GiftcardStore => {
    const store = useContext<GiftcardStore>(StoreContext);

    if (!store) {
        throw new Error('useGiftcardStore must be used within StoreProvider');
    }
    return store;
};
